import React from "react";
import Banner from "../Home/Banner";
import TextRow from "../Pillar/TextRow";
import DoubleCol from "../../components/DoubleCol/index";
import CtaWithForm from "../Home/CtaWithForm";
import GlobalCta from "../../components/GlobalCta";
import Footer from "../Home/Footer";
import ThreeCol from "../../components/ThreeCol";

import {
  legalTranscriptionHeaderdata,
  securityHeaderData,
  bannerSealData,
  contactusPopupData,
  thankYouPopUpData,
  securityData2,
  threeColData,
  securityPageCtaData,
  securityRowData,
  legalTranscriptionFooterData,
  SecuirtyctaWithFormData2,
} from "../utils/sample-data";
const Security = () => {
  return (
    <>
      <Banner
        data={securityHeaderData}
        headerData={legalTranscriptionHeaderdata}
        sealData={bannerSealData}
        contactusPopUpData={contactusPopupData}
        thankYouPopUpData={thankYouPopUpData}
      />
      <TextRow
        data={securityRowData}
        titleClassName="text-5xl text-blue text-center font-primary font-bold pb-10 mx-auto"
        descriptionClassName="text-lg leading-8 text-blue text-center font-opensans pb-8"
        rectangleBar="chahiye"
      />
      <DoubleCol
        background="bg-prussian-blue"
        background2="bg-prussian-blue"
        data={securityData2}
        titleClassName="text-5xl leading-[75px] text-white text-center font-primary font-bold pb-10"
        paraClassName="text-lg leading-8 text-slate font-opensans text-center pb-14"
        className=" flex xs:flex-col gap-6 xs:items-center sm:items-start mb-12 md:mb-0"
        h3class="text-xl text-white mb-3 font-primary font-semibold text-24px xs:text-center sm:text-left sm:w-72"
        descriptionClass="text-slate leading-8 font-opensans text-lg xs:text-center sm:text-left xs:mb-5 md:mb-0"
      />
      <ThreeCol data={threeColData} />
      <GlobalCta
        data={securityPageCtaData}
        line
        background="bg-gradient-to-r from-[#EB478C] to-[#EC9D5B] w-full mx-auto"
        className="flex xs:flex-col lg:flex-row items-center justify-between xs:gap-7 lg:gap-28 max-w-6xl mx-auto px-5 2xl:px-0 py-16"
      />
      <CtaWithForm
        data={SecuirtyctaWithFormData2}
        background="bg-blue"
        btnStyle="bg-yellow hover:bg-lightYellow"
      />
      <Footer data={legalTranscriptionFooterData} />
    </>
  );
};
export default Security;
