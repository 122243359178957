import Security from "../LegalTranscription/Secuirty-and-condfidentiality/index";
import React from "react";
import Seo from "../components/seo";

function Index() {
  return (
    <>
      <Seo
        title="Security & Confidentiality - LexTranscribe"
        description="LexTranscribe values the security & confidentiality of your data and information. We’ve taken the necessary steps to ensure the security of our platform and services."
        slug="/security-and-confidentiality"
      />
      <Security />
    </>
  );
}
export default Index;
